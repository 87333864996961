/**
 * Override styles for Hubspot Conversations chat widget.
 */

import { createGlobalStyle } from 'styled-components';

import mq from '@/styles/js/utils/mq';

const HubspotStyles = createGlobalStyle`
  html.hs-messages-mobile {
    &.hs-messages-widget-open body #hubspot-messages-iframe-container {
      height: calc(100% - env(safe-area-inset-top)) !important;
    }
    &:not(.hs-messages-widget-open) body #hubspot-messages-iframe-container {
      ${mq({ until: 'large' })} {
        bottom: 5.3rem !important;
      }
    }
  }

  html:not(.hs-messages-mobile) body #hubspot-messages-iframe-container {
    ${mq({ until: 'large' })} {
      bottom: 5.3rem !important;
    }
  }

  ${mq({ until: 500 })} {
    html.hide-chat-widget {
      #hubspot-messages-iframe-container {
        display: none !important;
      }
    }
  }
`;

export default HubspotStyles;
