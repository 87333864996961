import { css } from 'styled-components';

const formatFontStack = (stack) => stack.map((it) => `"${it}"`).join(',');

const avertaStack = formatFontStack([
  'averta-regular',
  'Avenir',
  'Helvetica Neue',
  'arial',
  'sans-serif',
]);

const camptonStack = formatFontStack([
  'Campton W00 Bold',
  'Helvetica Neue',
  'arial',
  'sans-serif',
]);

const averta = css`
  font-family: ${avertaStack};
  font-weight: normal;
  font-style: normal;
`;

const avertaBold = css`
  font-family: ${avertaStack};
  font-weight: bold;
  font-style: normal;
`;

const campton = css`
  font-family: ${camptonStack};
  font-weight: normal;
  font-style: normal;
`;

const fontStacks = {
  averta,
  'averta-bold': avertaBold,
  campton,
};

const fontStack = (key) => fontStacks[key];

export default fontStack;
