import { createContext, useContext, useMemo } from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});
const { Consumer, Provider } = Context;

export function CountryProvider({ children, serverData }) {
  const [{ data: countries, ...requestState }] = useDeskpassAPI(
    (api) => () => api.global.getCountries(),
    {
      // Set initial state when passed from the server
      ...(serverData.countries && {
        fireOnMount: false,
        initialState: {
          ready: true,
          data: serverData.countries,
        },
      }),
    },
  );

  const context = useMemo(
    () => ({
      countries,
      ...requestState,
    }),
    [countries, requestState],
  );

  return <Provider value={context}>{children}</Provider>;
}

export const useCountryContext = () => useContext(Context);
export const CountryConsumer = Consumer;
export const withCountryContext = consumerToHOC(Consumer, 'countryContext');
export default Context;
