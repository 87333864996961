'use client';

import PreloadResources from '@/components/graphics/PreloadResources';
import { HashScroller } from '@/components/helpers/HashScroller';
import { StatusBarArea } from '@/components/helpers/MobileDeviceArea';
import ScrollToTop from '@/components/helpers/ScrollToTop';

import AppContextProviders from '@/context/App';

import GlobalStyles from '@/styles/js/global';

export function RootContainer({ children, serverData }) {
  return (
    <>
      <GlobalStyles />
      <PreloadResources />

      <AppContextProviders serverData={serverData}>
        <StatusBarArea />
        <HashScroller />
        {children}
        <ScrollToTop />
      </AppContextProviders>
    </>
  );
}
