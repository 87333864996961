import { css } from 'styled-components';
import { parseValidMeasureUnitOrBreakpoint } from './units';

/*
 * Our styled replacement for sass-mq
 */
const mq = (args) => {
  let from, until;

  if (typeof args === 'object' && (!!args.from || !!args.until)) {
    from = parseValidMeasureUnitOrBreakpoint(args.from);
    until = parseValidMeasureUnitOrBreakpoint(args.until);
  } else {
    from = parseValidMeasureUnitOrBreakpoint(args);
  }

  // Prettier was breaking lines and adding ; to the end of the media
  // which would break them before we define brackets where this is called
  // so I added prettier-ignore here to make sure this doesn't happen...
  if (!!from && !!until) {
    return css`@media (min-width: ${from}) and (max-width: ${until})`;
  } else if (from) {
    return css`@media (min-width: ${from})`;
  } else if (until) {
    return css`@media (max-width: ${until})`;
  }

  // Else adds nothing to the styled template string
  return '';
};

export default mq;
