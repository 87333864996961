import React from 'react';

import { getCityAndSpaceCount } from '@/api/deskpass/feeds';

import useEvent from '@/hooks/useEvent';
import useMount from '@/hooks/useMount';

import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext();
const { Consumer, Provider } = Context;

export function MetaProvider({ children, serverData }) {
  const [metadata, setMetadata] = React.useState(() => ({
    ...(serverData.meta && {
      count: {
        city: serverData.meta.cityCount,
        space: serverData.meta.spaceCount,
      },
    }),
  }));
  const [loading, setLoading] = React.useState(() => true);

  const getSpaceAndCityCount = useEvent(async () => {
    const { cityCount, spaceCount } = await getCityAndSpaceCount();

    setMetadata({
      count: {
        city: cityCount,
        space: spaceCount,
      },
    });
    setLoading(false);
  });

  useMount(() => {
    if (serverData.meta) return;

    getSpaceAndCityCount();
  });

  const context = React.useMemo(
    () => ({
      metadata,
      loading,
      ready: !!serverData.meta || !loading,
    }),
    [metadata, loading, serverData.meta],
  );

  return <Provider value={context}>{children}</Provider>;
}

export const useMetaContext = () => React.useContext(Context);
export const MetaConsumer = Consumer;
export const withMetaContext = consumerToHOC(Consumer, 'metaContext');

export default Context;
