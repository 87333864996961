import NetworkClient from '@/lib/network/client';

export function createIpLocationClient(env) {
  const apiKey = env.IP_GEOLOCATION_KEY;
  const baseURL = 'https://ip-geolocation.whoisxmlapi.com';

  const client = new (class IpGeolocationNetworkClient extends NetworkClient {
    config = {
      baseURL,
    };
  })();

  return {
    /*
     * Docs:
     *
     * https://ip-geolocation.whoisxmlapi.com/api/documentation/making-requests
     */
    getGeolocationByIp(ipAddress) {
      if (!apiKey) {
        console.error('Missing IpGeolocation API key.');

        // ipGeolocation shouldn't put anything to a screeching halt
        return Promise.resolve('missingIpGeolocationKey');
      }

      let args = { params: { apiKey } };

      if (env.NODE_ENV !== 'development' && ipAddress) {
        args.params.ipAddress = ipAddress;
      }

      return client.get('/api/v1', args);
    },
  };
}
