import { useMediaQuery as useMediaQueryResponsive } from 'react-responsive';

import { allBreakpoints } from '@/lib/breakpoints';

/*
 * Expose useMediaQuery hook but accepts our naming conventions
 */
const useMediaQuery = (props = {}) => {
  const { min, max, ...rest } = props;

  let overrideProps = {};

  if (min) {
    overrideProps.minWidth = allBreakpoints()[min];
  }

  if (max) {
    overrideProps.maxWidth = allBreakpoints()[max] - 1;
  }

  return useMediaQueryResponsive({
    ...rest,
    ...overrideProps,
  });
};

export default useMediaQuery;
