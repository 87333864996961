import { useMemo, useState } from 'react';
import { Context as ResponsiveContext } from 'react-responsive';

import { useMountAsync } from '@/hooks/useMount';

import { breakpoints } from '@/lib/breakpoints';

export function UserAgentProvider({ children, serverData }) {
  const { userAgent } = serverData;
  const deviceType = userAgent?.device?.type ?? 'desktop';

  const [width, setWidth] = useState(() => {
    return breakpoints[deviceType === 'desktop' ? 'large' : 'small'];
  });

  /*
   * ASAP we let the client side take over and let react-responsive
   * define the width as it does internally.
   */
  useMountAsync(() => {
    setWidth();
  });

  /*
   * This value is set so that react-reponsive can know what the device width
   * the server identified from the User Agent. This is useful for SSR.
   */
  const context = useMemo(() => {
    if (width) {
      return {
        width,
        deviceWidth: width,
      };
    }

    return null;
  }, [width]);

  return (
    <ResponsiveContext.Provider value={context}>
      {children}
    </ResponsiveContext.Provider>
  );
}
