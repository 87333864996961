import React from 'react';

import { createIpLocationClient } from '@/api/ipGeolocation';

import useAPI from '@/hooks/useAPI';

import config from '@/lib/config';
import { consumerToHOC } from '@/lib/hoc';

const Context = React.createContext({});

const { Consumer, Provider } = Context;

function mapLocationData(location) {
  if (!location) {
    return null;
  }

  const { country, region, city, lat, lng } = location;

  return {
    country,
    city,
    region,
    coords: {
      lat,
      lng,
    },
  };
}

const ipGeolocationAPI = createIpLocationClient(config);

export function IpLocationProvider({ children, serverData }) {
  const [{ ready, data: { location } = {} }] = useAPI(
    ipGeolocationAPI,
    (api) => api.getGeolocationByIp,
    {
      // Don't load ip-location if URL params lat/lng are provided
      fireOnMount: !serverData.ipLocation,
      ...(serverData.ipLocation && {
        initialState: {
          available: true,
          ready: true,
          location: mapLocationData(serverData.ipLocation.location),
        },
      }),
    },
  );

  const context = React.useMemo(() => {
    if (!location) {
      return {
        ready,
        available: false,
        location: null,
      };
    }

    return {
      available: true,
      ready,
      location: mapLocationData(location),
    };
  }, [location, ready]);

  return <Provider value={context}>{children}</Provider>;
}

export const IpLocationConsumer = Consumer;
export const useIpLocationContext = () => React.useContext(Context);
export const withIpLocationContext = consumerToHOC(
  Consumer,
  'ipLocationContext',
);
export default Context;
