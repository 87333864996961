'use client';

import styled from 'styled-components';

import { defaultColors } from '@/styles/js/colors';
import { loadingAnimation } from '@/styles/js/utils/animation';
import eases from '@/styles/js/utils/eases';
import { absolute as positionAbsolute } from '@/styles/js/utils/position';
import { absolute as pseudoAbsolute } from '@/styles/js/utils/pseudo';

export const GlobalLoading = styled.div`
  width: 100%;
  min-height: 50vh;

  &::before {
    ${pseudoAbsolute}
    ${positionAbsolute}

    margin: auto;
    width: 3rem;
    height: 3rem;
    border: 3px solid ${defaultColors.rule};
    border-radius: 100%;
    border-right-color: ${defaultColors.ruleDark};
    animation-name: ${loadingAnimation};
    animation-duration: 0.5s;
    animation-timing-function: ${eases['linear']};
    animation-iteration-count: infinite;
    will-change: transform;
  }

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgb(255 255 255 / 90%);
  z-index: 999;
`;
