import { createGlobalStyle } from 'styled-components';

import fontSmoothing from '@/styles/js/fonts/smoothing';
import fontStack from '@/styles/js/fonts/stacks';

import { defaultColors } from '@/styles/js/colors';
import units from '@/styles/js/units';
import mq from '@/styles/js/utils/mq';

/*
 * General Style Definitions
 */
const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #fff;
    height: 100%;
  }

  body {
    overscroll-behavior-y: none;
    overflow: auto;
    overflow-x: hidden;
  }

  #app-root {
    height: 100%;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  body {
    ${fontStack('averta')}
    font-size: 1.5rem;
    color: ${defaultColors.text};

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      ${fontSmoothing}
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img,
  svg {
    display: block;
    height: auto;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  // Remove gray tap highlight color on iOS Safari
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  // react-overlays will add "overflow: hidden"
  // so we need to override it.
  .modal-root {
    overflow: visible !important;
    position: relative;
    z-index: ${units.zIndex.modal};
  }

  /* Customization styles for Intercom */
  #intercom-container {
    ${mq('large')} {
      &&& {
        z-index: ${units.zIndex.intercom};
      }
      .svg-icon path {
        fill: pink;
      }
    }
  }

  /**
   * Prevents issue where HTML and Body scrollbars
   * are visible or in focus when a modal is open.
   * 
   * Attepmt to scroll the modal may result to scrolling the body instead.
   * 
   * Note: This is a workaround for the issue.
   */
  html.modal-open,
  html.modal-open > body {
    ${mq({ until: 'medium' })} {
      overflow: hidden;
    }
  }
`;

export default GlobalStyle;
