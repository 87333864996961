import { createGlobalStyle, css } from 'styled-components';

function makeAvertaFontUrl(fontName) {
  return `/fonts/averta/${fontName}`;
}

function makeCamptonFontUrl(fontName) {
  return `/fonts/campton/${fontName}`;
}

const fontFaceProps = (family, weight = 'normal', style = 'normal') => css`
  font-family: ${family};
  font-weight: ${weight};
  font-style: ${style};
`;

const GlobalFonts = createGlobalStyle`
  @font-face {
    src: url(${makeCamptonFontUrl('campton.woff2')}) format('woff2'),
      url(${makeCamptonFontUrl('campton.woff')}) format('woff'),
      url(${makeCamptonFontUrl('campton.ttf')}) format('truetype');
    ${fontFaceProps('Campton W00 Bold', 'bold')}
  }


  @font-face {
    src: url(${makeAvertaFontUrl('averta-regular-webfont.woff2')}) format('woff2'),
      url(${makeAvertaFontUrl('averta-regular-webfont.woff')}) format('woff');
    ${fontFaceProps('averta-regular')}
  }

  @font-face {
    font-family: 'averta-regular';
    src: url(${makeAvertaFontUrl('averta-regular-italic-webfont.woff2')}) format('woff2'),
      url(${makeAvertaFontUrl('averta-regular-italic-webfont.woff')}) format('woff');
    ${fontFaceProps('averta-regular', 'normal', 'italic')}
  }

  @font-face {
    font-family: 'averta-regular';
    src: url(${makeAvertaFontUrl('averta-bold-webfont.woff2')}) format('woff2'),
      url(${makeAvertaFontUrl('averta-bold-webfont.woff')}) format('woff');
    ${fontFaceProps('averta-regular', 'bold')}
  }
`;

export default GlobalFonts;
