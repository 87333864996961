'use client';

import ReactDOM from 'react-dom';

function preloadSVGs(filepath) {
  ReactDOM.preload(filepath, {
    as: 'image',
  });
}

export function PreloadResources() {
  preloadSVGs('/svgs/full.sprite.svg');
  preloadSVGs('/svgs/icons.sprite.svg');

  return null;
}

export default PreloadResources;
