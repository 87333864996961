export function ComposeChildren({ components, children, withProps = {} }) {
  return (
    <>
      {components.reduceRight((acc, CompOrArray) => {
        if (Array.isArray(CompOrArray)) {
          const [Comp, props] = CompOrArray;
          return (
            <Comp {...withProps} {...props}>
              {acc}
            </Comp>
          );
        }

        return <CompOrArray {...withProps}>{acc}</CompOrArray>;
      }, children)}
    </>
  );
}
